import styled from "@emotion/styled";
import React, {InputHTMLAttributes, useMemo} from "react";
import {css} from "@emotion/react";
import {formatNumber} from "../../utils";

const InputContainer = styled.div`
  display: block;
  width: 100%;
`

const StyledInput = styled.input`
  background-color: var(--secondary-bg-color);
  padding: 8px 14px;
  height: 36px;
  border: 0;
  border-radius: var(--border-radius-large);
  color: inherit;
  outline: none;
  font-size: 15px;
  width: 100%;

  ${props => props.readOnly && css`
    cursor: default;
    opacity: 0.7;
  `}
`

const Label = styled.div`
  font-size: 14px;
  margin-bottom: var(--space-gap-2x);
`

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string,
  errorLabel?: any,
    // doFormatNumber?: boolean
}

const Input: React.FunctionComponent<InputProps> = ({label, errorLabel, /* doFormatNumber, */ ...props}) => {
    // const value = useMemo(() => {
    //     if (!doFormatNumber) {
    //         return props.value
    //     }
    //
    //     const parsed = parseFloat(props.value as string)
    //
    //     if (isNaN(parsed)) {
    //         return props.value
    //     }
    //
    //     return formatNumber(parsed)
    // }, [doFormatNumber, props.value])
    return <InputContainer>
        {(label) && <Label>{label}</Label>}
        <StyledInput
            {...props}
            // value={value}
        />
      {(errorLabel) && <Label style={{color: "red"}}>{errorLabel}</Label>}
    </InputContainer>
}
export default Input
