import React from "react";
import {BalanceProps} from "./types";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import IconLogotype from "../../icons/IconLogotype";
import {formatNumber} from "../../utils";

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--space-gap-2x);
`
const CardBalance = styled.div`
  flex: 1;
  height: 70px;
  padding: 10px;
  border-radius: var(--border-radius-normal);
`
const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: var(--button-text-color);
  margin-bottom: 5px;
`
const Value = css`
  font-size: 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
`
const ValueActual = styled.div`
  ${Value};
  color: var(--button-text-color);
`
const ValueExpectation = styled.div`
  ${Value};
  color: var(--button-text-color);
`

const ActualBalance = styled(CardBalance)`
  background: var(--linear-gradient-balance-actual);
`

const ExpectationBalance = styled(CardBalance)`
  background: var(--linear-gradient-balance-expectation)

`


export const Balance: React.FC<BalanceProps> = ({actual = 0, expectation}) => {
    return <BalanceContainer>
        <ActualBalance>
            <Title>Баллы</Title>
            <ValueActual><IconLogotype width={20} color={"#fff"}
                                       fill={"var(--logo-freshback-color)"}/>{formatNumber(actual)}</ValueActual>
        </ActualBalance>
        {/*<ExpectationBalance>*/}
        {/*    <Title>Ожидают</Title>*/}
        {/*    <ValueExpectation><IconLogotype width={20} color={"#fff"}*/}
        {/*                                    fill={"var(--logo-freshback-color)"}/>{formatNumber(expectation)}*/}
        {/*    </ValueExpectation>*/}
        {/*</ExpectationBalance>*/}
    </BalanceContainer>
}
