import styled from "@emotion/styled";
import Selector from "../../../components/Selector";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSalesUnits } from "../../stores/useSalesUnits";
import { useFreshbackApi } from "../../stores/useFreshbackApi";
import { Button } from "../../../components";
import env from "../../../data/env";
import IconQr from "../../../icons/IconQr";
import PhoneInput from "../../../components/PhoneInput";
import IconLogotype from "../../../icons/IconLogotype";
import { formatNumber, getNicePercentageNumber, getNormalizedPhone, parseNiceFloat } from "../../../utils";
import moment from "moment/moment";
import Input from "../../../components/Input";
import { SelectorItem } from "../../../components/Selector/types";
import { PAYMENT_TYPE } from "../../../data/constant";


const ContainerAddTransaction = styled.div`
    flex: 1;
    padding: var(--space-gap-5x) 10px;
    margin: 0 -10px;
    background: var(--bg-color);
    z-index: 3;
    border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
`
const Header = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: var(--hint-color);
    text-transform: uppercase;
    letter-spacing: 0.02rem;
`
const BlockAddTransaction = styled.div`
    margin: var(--space-gap-4x) 0 var(--space-gap-4x)
`
const BlockAddTransactionWrapper = styled.div`
    margin: var(--space-gap-4x) 0 var(--space-gap-5x) 0;
    flex: 1;
`
const BalanceCard = styled.div`

`
const BalanceCardTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
`
const BalanceCardValue = styled.div`
    font-size: 26px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: var(--space-gap-2x);
    margin-top: calc(var(--space-gap-2x) + 2px)
`
const BlockGroup = styled.div`
    display: flex;
    gap: var(--space-gap-4x);
    justify-content: space-between;
`
const SmsCodeWrapper = styled.div`
    color: var(--text-color);
    width: 90vw;
    border-radius: var(--border-radius-normal);
    padding: var(--space-gap-3x);
    background: var(--bg-color);
    display: flex;
    flex-direction: column;
    gap: 10px;
`

function parseJWT(token: string) {
  try {
    const [header, payload, signature] = token.split('.');
    if (!header || !payload || !signature) {
      throw new Error('Invalid JWT format');
    }

    const decodedHeader = JSON.parse(decodeURIComponent(escape(atob(header))));
    const decodedPayload = JSON.parse(decodeURIComponent(escape(atob(payload))));

    return {
      header: decodedHeader,
      payload: decodedPayload,
      signature: signature
    };
  } catch (error) {
    console.error('Error parsing JWT');
    return null;
  }
}

interface UserInterface {
  phone: string,
  balance: number,
  token: string,
  info?: {
    tags?: Array<{ id: number, name: string }>,
    id?: number,
    name?: string,
    birthday?: string,
    sex?: string
  }
}
function getPercentage(part: number, total: number) {
  if (total === 0) {
    return 0
  }
  return parseNiceFloat((part / total) * 100);
}

function hasPermission(value: number, bitPosition: number) {
  return (value & (1 << bitPosition)) !== 0;
}
export const AddTransactionPageV2 = () => {
  const api = useFreshbackApi(selector => selector.api)
  const { salesUnits } = useSalesUnits()

  const [shopId, setShopId] = useState(0)
  const [amount, setAmount] = useState<number>()
  const [points, setPoints] = useState<number>()
  const [marketingCampaign, setMarketingCampaign] = useState<any>()
  const [cashBackSum, setCashBackSum] = useState<number>()
  const [paymentType, setPaymentType] = useState<number>()

  const [shopToken, setShopToken] = useState<string>()

  const [shopInfo, setShopInfo] = useState<any>()
  const [cashBackPercent, setCashBackPercent] = useState<{ value: string, computed: boolean }>({
    value: "",
    computed: false
  })
  const [user, setUser] = useState<UserInterface>({ phone: "", balance: 0, token: "" })

  const [paymentTypes, setPaymentTypes] = useState<SelectorItem<number>[]>(PAYMENT_TYPE)

  useEffect(() => {
    api.getPaymentTypes().then((response) => {
      setPaymentTypes(response.filter(item => item.id !== 4).map(item => ({id: item.id, option: item.name})))
    })
  }, [])

  useEffect(() => {
    if (shopId) {
      setMarketingCampaign(undefined)
      api.request.get(`/api/sales-units/${shopId}/sales-registration-token`)
        .then(({ data }) => {
          setShopToken(data)
          setShopInfo(parseJWT(data)?.payload)
        })
        .catch(() => {
          setShopToken(undefined)
          setShopInfo(undefined)
        })
    }
  }, [shopId])

  useEffect(() => {
    if (marketingCampaign) {
      const term = marketingCampaign?.data?.marketingCampaignTerms.sort((a: any, b: any) => a.cashbackTerm - b.cashbackTerm)[marketingCampaign?.data?.marketingCampaignTerms.length - 1].cashbackTerm
      setCashBackPercent({
        value: ( getNicePercentageNumber(term) / 2).toString(),
        computed: true
      })
    } else {
      setCashBackPercent({
        value: 0..toString(),
        computed: false
      })
    }
  }, [marketingCampaign]);


  useEffect(() => {

    setCashBackSum(getNicePercentageNumber(parseNiceFloat(amount || 0, false) as number) * (parseNiceFloat(cashBackPercent.value, false) as number))

  }, [amount, cashBackPercent])


  const pointsResult = useMemo(() => {
    return getPercentage(points || 0, amount || 0)
  }, [points, amount, cashBackPercent])

  const daysBeforeDr = useMemo(() => {
    if (!user.info?.birthday) {
      return undefined
    }
    const bdayMoment = moment(user.info?.birthday)
    return bdayMoment.isValid() ? moment().startOf("day").diff(bdayMoment.set("year", moment().get("year")).startOf("day"), "days") : undefined
  }, [user])

  const amountError = useMemo(() => {
    if (!marketingCampaign || !amount) return false

    const max = getNicePercentageNumber(marketingCampaign?.data?.maxSalesAmountLimit || 0)

    return amount >= max
  }, [amount, marketingCampaign])

  const maxPoints = useMemo(() => {
    const num = getNicePercentageNumber(getNicePercentageNumber(marketingCampaign?.data?.maxPercentagePointsPaymentLimit) * (amount || 0))
    if (num > (user.balance || 0)) {
      return user.balance || 0
    }

    return num
  }, [marketingCampaign, amount, user])

  const pointsError = useMemo(() => {
    if ((points || 0) > (user.balance || 0)) {
      return true
    }

    return pointsResult > getNicePercentageNumber(marketingCampaign?.data?.maxPercentagePointsPaymentLimit || 0)
  }, [pointsResult, marketingCampaign])

  const bonus = useMemo(() => {
    if (!shopInfo?.permissions?.options) {
      return undefined
    }

    if (!hasPermission(shopInfo?.permissions?.options, 2)) {
      return undefined
    }

    return (parseNiceFloat((amount || 0) - (points || 0)) * (parseNiceFloat(shopInfo.cashierBonus) / 10000)) + ( user?.info?.id ? 0 : 30 )
  }, [marketingCampaign, amount, points, user])

  const isValid = (() => {
    if (amountError) {
      return false
    }

    if (pointsError) {
      return false
    }

    if (!paymentType) {
      return false
    }

    if (!user.info?.id && !user.info?.sex) {
      return false
    }

    if (!shopId) {
      return false
    }

    if (parseNiceFloat(amount || 0, false) === 0) {
      return false
    }

    if (parseNiceFloat(cashBackPercent.value || 0, false) === 0) {
      return false
    }

    return true

  })()

  const onSubmit = useCallback(async () => {}, [

  ])

  return (<>

    <ContainerAddTransaction>
      <Selector
        header={"Выберите торговую точку"}
        selected={shopId}
        label={"Торговая точка"}
        onSelect={(e) => {
          setShopId(e.item.id)
        }}
        items={salesUnits.map(item => ({
          id: item.id,
          option: item.name,
          data: item
        }))}
      />
      <BlockAddTransaction>
        <Header>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <span>Клиент</span>
          </div>
        </Header>
        <BlockGroup>
          <BlockAddTransactionWrapper>
            <PhoneInput
              label={"Телефон"}
              placeholder={"+7 999 999 99 99"}
              value={user.phone}
              onChange={async (e) => {
                setUser({
                  phone: e.target.value.trimStart(),
                  balance: 0,
                  token: ""
                })

                const phone = getNormalizedPhone(e.target.value.trimStart())

                if (phone.length === 11) {

                  try {
                    const userData = await api.getUserByPhone(phone)
                    const balance = await api.getEmplBalance(userData.id)
                    const { data: token } = await api.request.get(`/api/profiles/${userData.id}/sales-registration-token`)

                    setUser({
                      phone: e.target.value.trimStart(),
                      balance: balance.currentBalance,
                      token: token,
                      info: {
                        tags: userData.tags ?? [],
                        id: userData.id,
                        name: userData.firstName ?? "",
                        sex: userData.gender ?? "",
                        birthday: userData.birthday ? moment(userData.birthday * 1000).format("YYYY-MM-DD") : ""
                      }
                    })
                  } catch (error) {

                  }
                }
              }}
            />
          </BlockAddTransactionWrapper>
          <BlockAddTransactionWrapper>
            <BalanceCard>
              <BalanceCardTitle>ID {user.info?.id ? (`№${user.info?.id}`) : ""}</BalanceCardTitle>
              <BalanceCardValue><IconLogotype width={20} color={"#fff"} fill={"var(--logo-freshback-color)"}/><span
                style={{ color: user.balance < 0 ? "var(--text-error)" : "var(--text-success)" }}>{formatNumber(user.balance)}</span></BalanceCardValue>
            </BalanceCard>
          </BlockAddTransactionWrapper>
        </BlockGroup>
        <BlockGroup>
          <BlockAddTransactionWrapper style={{ marginTop: 0 }}>
            <Input
              label={"Имя *"}
              placeholder={"Имя"}
              value={user.info?.name || ""}
              disabled={!!user.info?.id}
              onChange={(e) => {
                if (!user.info?.id) {
                  setUser(p => ({
                    ...p,
                    info: {
                      ...(p.info || {}),
                      name: e.target.value.trimStart()
                    }
                  }))
                }
              }}
            />
          </BlockAddTransactionWrapper>
          <BlockAddTransactionWrapper style={{ marginTop: 0 }}>
            <Input
              label={"Дата рождения"}
              placeholder={"Дата рождения"}
              type={"date"}
              value={user.info?.birthday || ""}
              disabled={!!user.info?.id}
              onChange={(e) => {
                if (!user.info?.id) {
                  setUser(p => ({
                    ...p,
                    info: {
                      ...(p.info || {}),
                      birthday: e.target.value
                    }
                  }))
                }
              }}
              style={{ border: `1px solid ${daysBeforeDr === -1 ? "yellow" : (daysBeforeDr === 0 ? "gold" : (daysBeforeDr === 1 ? "#ff5e00" : "transparent"))}` }}
            />
          </BlockAddTransactionWrapper>
        </BlockGroup>
        {(user.info?.id && user.info?.tags?.length) ? (
          <div>
            {user.info?.tags?.map(tag => (
              <div style={{
                display: "inline-block",
                padding: "2px 8px",
                border: " 1px solid var(--button-color)",
                borderRadius: "5px",
                color: "var(--text-color)",
                marginRight: "6px",
                marginBottom: "6px",
              }} key={tag.id}>{tag.name}</div>
            ))}
          </div>
        ) : ""}
        {(!user.info?.id || user.info?.id === 0) && (
          <BlockGroup>
            <BlockAddTransactionWrapper style={{ marginTop: 0 }}>
              <Button onClick={() =>
                setUser(p => ({
                  ...p,
                  info: {
                    ...(p.info || {}),
                    sex: "мужской"
                  }
                }))
              } style={{
                fontSize: 10,
                filter: `brightness(${user.info?.sex === "мужской" ? "1.4" : "1"})`
              }}>Муж</Button>
            </BlockAddTransactionWrapper>
            <BlockAddTransactionWrapper style={{ marginTop: 0 }}>
              <Button onClick={() => setUser(p => ({
                ...p,
                info: {
                  ...(p.info || {}),
                  sex: "женский"
                }
              }))} style={{
                fontSize: 10,
                filter: `brightness(${user.info?.sex === "женский" ? "1.4" : "1"})`
              }}>Жен</Button>
            </BlockAddTransactionWrapper>
          </BlockGroup>
        )}
      </BlockAddTransaction>

      <BlockAddTransaction>
        <Header>Продажа</Header>
        <Selector
          header={"Выберите акцию"}
          selected={marketingCampaign?.id}
          label={"Акция"}
          onSelect={(e) => {
            setMarketingCampaign(e.item)
          }}
          items={[
            {
              id: undefined,
              option: "Без акции",
              data: undefined
            }
          ].concat(shopInfo?.marketingCampaigns?.map((item: any) => ({
            id: item.id,
            option: item.name,
            data: item
          })) || [])}
        />

        <BlockGroup>
          <BlockAddTransactionWrapper style={{ flex: 1 }}>
            <div>
              <Input
                label={"Сумма"}
                placeholder={"..."}
                type={"number"}
                errorLabel={amountError && "Сумма больше установленной лимита"}
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value) === 0 ? undefined : Number(e.target.value))}
              />
            </div>
          </BlockAddTransactionWrapper>
          <BlockGroup style={{ flex: 1 }}>
            <BlockAddTransactionWrapper>
              <div>
                <Input
                  label={"Кешбэк %"}
                  placeholder={"0%"}
                  type={"number"}
                  value={cashBackPercent.value}
                  readOnly={true}
                  onChange={(e) => setCashBackPercent({
                    value: e.target.value,
                    computed: false
                  })}
                />
              </div>
            </BlockAddTransactionWrapper>
            <BlockAddTransactionWrapper>
              <div>
                <Input
                  label={"Кешбэк"}
                  placeholder={"0"}
                  value={cashBackSum}
                  readOnly={true}
                />
              </div>
            </BlockAddTransactionWrapper>
          </BlockGroup>
        </BlockGroup>
        <BlockGroup>
          {paymentTypes.map(type => {
            return <Button
              style={{fontSize: 10, filter: `brightness(${type.id === paymentType ? "1.4" : "1"})`}}
              onClick={() => {

                if (type.id === 4) {
                  // if (userPhone.length === 0 && userId) {
                  //     // @ts-ignore
                  //     webapp.showAlert("Введите номер телефона")
                  // }
                  // @ts-ignore
                  if (!user.info?.id && webapp) {
                    // @ts-ignore
                    webapp.showAlert("Введите номер телефона")
                  }
                }

                setPaymentType(type.id)

              }}>{type.option}</Button>
          })}
        </BlockGroup>
        <BlockGroup>
          <BlockAddTransactionWrapper style={{ flex: 1 }}>
            <div>
              <Input
                label={"Баллами"}
                placeholder={"..."}
                type={"number"}
                errorLabel={pointsError && "Сумма больше установленной лимита"}
                value={points}
                onChange={(e) => setPoints(Number(e.target.value) === 0 ? undefined : Number(e.target.value))}
              />
            </div>
          </BlockAddTransactionWrapper>
          <BlockGroup style={{ flex: 1 }}>
            <BlockAddTransactionWrapper style={{display: "flex", alignItems: "end"}}>
              <div>
                <Button onClick={() => {
                  setPoints(maxPoints)
                }}>
                  MAX {marketingCampaign?.data?.maxPercentagePointsPaymentLimit ? (
                  getNicePercentageNumber(marketingCampaign?.data?.maxPercentagePointsPaymentLimit)
                  ) : 0}%
                </Button>
              </div>
            </BlockAddTransactionWrapper>
            <BlockAddTransactionWrapper>
              <div>
                <Input
                  label={"%"}
                  placeholder={"0"}
                  value={pointsResult}
                  readOnly={true}
                />
              </div>
            </BlockAddTransactionWrapper>
          </BlockGroup>
        </BlockGroup>
        <div style={{textAlign: "center"}}>
         <b> К оплате: {parseNiceFloat((amount || 0) - (points || 0))}</b>
        </div>
        <Button
          onClick={onSubmit}
          style={{height: 70}}
          disabled={!isValid}>
          Провести продажу
          {bonus && (<><br/> {"* бонус сотрудника " + bonus}</>)}
        </Button>
        <BlockGroup style={{marginTop: 10}}>
          {/*{error}*/}
        </BlockGroup>
      </BlockAddTransaction>
    </ContainerAddTransaction>

  </>)
}
