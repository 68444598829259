export function parseNiceFloat<T extends string | number, B extends boolean = false>(_str: T, returnString?: B): B extends true ? string : number {

    const str = typeof _str !== "string" ? _str.toString() : _str

    const parsed = parseFloat(str)

    if (str.trim() === "" && returnString) {
        return "" as any
    }

    if ((str.trim() === "." || str.trim() === ",") && returnString) {
        return "0." as any
    }

    if (isNaN(parsed)) {
        return 0 as any
    }


    if (returnString) {
        let retS = str.replace(new RegExp('\\D+', 'gm'), ".")

        retS = retS.at(0) === "0" && retS.at(1) && retS.at(1) !== "." ? retS.slice(1) : retS

        const dotCount = retS.match(/\./g)?.length

        if (dotCount && dotCount > 1) {
            const respArr = retS.split(".")
            retS = respArr.shift() + '.' + respArr.join('')
        }

        return retS as any
    }

    return Number(parsed.toFixed(2)) as any
}

export function getNicePercentageNumber(num?: number) {
    if (!num) return 0
    return parseNiceFloat((num / 100).toFixed(2)) as number
}
