import React, {useEffect, useState} from "react";
import {useFreshbackApi} from "../../stores/useFreshbackApi";
import {BalanceResponse} from "../../../http";
import {useTransactions} from "../../stores/useTransactions";
import {AppMessage, NotificationCard, TransactionCard, TransactionList, TransactionListDate} from "../../../components";
import IconCodeCurly from "../../../icons/IconCodeCurly";
import IconLogotype from "../../../icons/IconLogotype";
import IconNote from "../../../icons/IconNote";
import {Loader} from "../../../components/Loader";

const TransactionPage: React.FC = () => {
    const api = useFreshbackApi((state) => state.api)
    const [balance, setBalance] = useState<BalanceResponse>({
        currentBalance: 0,
        expectationBalance: 0
        // distributor: {
        //     totalCompleted: 0
        // },
        // tenant: {
        //     totalCompleted: 0
        // },
        // employee: {
        //     totalCompleted: 0
        // },
        // total: {
        //     totalCompleted: 0
        // }
    })

    const { transactions, loading, setLoading, setTransactions } = useTransactions()

    useEffect(() => {

        api.getBalance().then((response) => {
            setBalance(response)
        })

        api.getNormalizeTransactions().then((response) => {

            setTransactions(response)
        }).finally(() => {
            setLoading(false)
        })


    }, [])

    if (loading) {
        return <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Loader withoutContainer />
        </div>
    }

    if (Object.keys(transactions).length === 0) {
        return <AppMessage text={"Нет транзакций"} icon={<IconLogotype fill={"var(--bg-color)"} color={"var(--link-color)"} />}/>
    }

    return <TransactionList>
        {
            Object.keys(transactions).map((transaction_date) => {
                return <React.Fragment key={transaction_date}>
                    <TransactionListDate>{transaction_date}</TransactionListDate>
                    {transactions[transaction_date].map(transaction => {
                        return <TransactionCard
                            key={transaction.id}
                            {...transaction}
                        />
                    })}
                </React.Fragment>
            })
        }
    </TransactionList>
}

export default TransactionPage
