import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    AppMessage,
    Balance,
    BottomNavigator,
    FlexContainer,
    Header,
    SectionContainer,
    useTelegramWebApp
} from "../../components";
import {BottomNavigatorItem} from "../../components/BottomNavigatorItem";
import {StickyContainer} from "../../components/StickyContainer";
import {useUser} from "../stores/useUser";
import {BalanceResponse, BotApi, FreshbackApi} from "../../http";
import {useFreshbackApi} from "../stores/useFreshbackApi";
import TransactionPage from "./pages/TransactionPage";
import { SearchPage, NotificationPage, UserPage } from "./pages";

import IconLogotype from "../../icons/IconLogotype";
import IconSearch from "../../icons/IconSearch";
import IconNotification from "../../icons/IconNotification";
import IconBar from "../../icons/IconBar";
import IconHome from "../../icons/IconHome";
import {useActivePage} from "../stores/useActivePage";
import {useNotifications} from "../stores/useNotifications";
import IconCodeCurly from "../../icons/IconCodeCurly";
import botApi from "../../http/botApi";
import { QrCodePage } from "./pages/QrCodePage";
import IconQr from "../../icons/IconQr";
import IconUserPin from "../../icons/IconUserPin";
import IconUserCircle from "../../icons/IconUserCircle";
import IconUser from "../../icons/IconUser";
import { UserModal } from "./features/user-modal";

export const MainPage = () => {
    const api = useFreshbackApi((state) => state.api)
    const user = useUser((state) => state.user)
    const setUser = useUser((state) => state.setUser)
    const externalUserInfo = useUser((state) => state.externalUserInfo)
    const setExternalUserInfo = useUser((state) => state.setExternalUserInfo)
    const [balance, setBalance] = useState<BalanceResponse>({
        currentBalance: 0,
        expectationBalance: 0
        // distributor: {
        //     totalCompleted: 0
        // },
        // tenant: {
        //     totalCompleted: 0
        // },
        // employee: {
        //     totalCompleted: 0
        // },
        // total: {
        //     totalCompleted: 0
        // }
    })

    const { activePageId, setActivePage, pages, setPages, ActiveComponent, setBadgeCount } = useActivePage()
    const { setNotifications } = useNotifications()

    useEffect(() => {


        api.getBalance().then((response) => {
            setBalance(response)
        })

        api.getUserInfo().then(u => setExternalUserInfo(u))

        api.getNotifications().then((response) => {
            setBadgeCount("NotificationPage", response.filter(item => !item.viewed).length)
            setNotifications(response)
        })


        setPages({
            "SearchHome": {
                component: <SearchPage />,
                title: "Поиск",
                icon: <IconHome />,
                // component: <AppMessage text={"Страница в разработке"} icon={<IconCodeCurly />}/>,
                // icon: <IconHome />,
                // label: "Поиск"
            },
            "SearchPage": {
                // component: <SearchPage />,
                // title: "Поиск",
                // icon: <IconSearch />,
                component: <QrCodePage />,
                icon: <IconQr />,
                // label: "Поиск"
            },
            "MainPage": {
                component: <TransactionPage />,
                icon: <IconLogotype color={"#fff"} fill={"var(--logo-freshback-color)"} id={"MainButtonIcon"}/>,
            },
            "NotificationPage": {
                component: <NotificationPage />,
                icon: <IconNotification />,
                // label: "Уведомления"
            },
            "SettingsPage": {
                component: <UserPage />,
                icon: <IconUser />,
                // label: "Настройки"
            },
        })

        setActivePage(!user.activePage ? "MainPage" : user.activePage)

    }, [])

    const isInitialMount = useRef(true);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (activePageId !== "SearchHome") {
                botApi.setOnPage(activePageId).then(item => setUser(item))
            }
        }
    }, [activePageId]);


    return (
        <FlexContainer style={{paddingBottom: 60}}>
            {externalUserInfo && <UserModal /> }
            <SectionContainer>
                {activePageId !== "SettingsPage" && <StickyContainer className={"user-container"}>
                    <Header phone={user.phone}/>
                </StickyContainer>}
                {activePageId === "MainPage" && <StickyContainer className={"balance-container"}>
                    <Balance
                        // actual={balance.total.totalCompleted}
                      actual={balance.currentBalance}
                      expectation={balance.expectationBalance}
                    />
                </StickyContainer>}
                <>
                    {ActiveComponent()}
                </>
            </SectionContainer>
            <BottomNavigator>
                {
                    Object.keys(pages).map(page_id => {
                        return <BottomNavigatorItem key={page_id} label={pages[page_id].label} isActive={page_id === activePageId} badgeCount={pages[page_id].badgeCount} onClick={() => setActivePage(page_id)}>
                            {pages[page_id].icon}
                        </BottomNavigatorItem>
                    })
                }
            </BottomNavigator>
        </FlexContainer>
    );
}
